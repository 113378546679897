<template>
	<div class="page">
		<div class="main">
			<van-row class="desc">
				<van-col span="24" v-html="desc" style="margin-bottom: 20px;"></van-col>
			</van-row>

			<van-row>
				<van-col span="2"><input type="checkbox" :checked="checked" @click="changePolicy"></van-col>
				<van-col span="22" style="color: #6C6C6C;line-height: 150%;">
					{{translate('agree_to_the_relevant')}} <span style="color: #FB6B23;" @click="goInfo">{{term_title}}</span>
				</van-col>
			</van-row>
		</div>

		<van-row class="footerBtn">
			<van-button color="#FB6B23" block :disabled="disabled" @click="goExam">{{translate('start_answering')}}</van-button>
		</van-row>
		
	</div>
</template>

<script>
	export default {
		name: 'risk_index',
		data() {
			return {

				token: "",

				title: "",
				desc: "",

				term_title: "",
				term_id: "",

				checked: false,
				disabled: true,
			}
		},
		mounted() {

			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}

			// 储存token
			sessionStorage.setItem("token", this.$route.query.token)

			this.token = this.$route.query.token

			this.onLoadData()
		},
		methods: {
			onLoadData(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'fund_risk/index',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {

						this.title = res.data.title
						this.desc = res.data.desc

						this.term_id = res.data.term_id
						this.term_title = res.data.term_title
						
					} else {
						this.$toast.fail(res.error_text)
					}
				})
			},

			goExam() {
				this.$router.push('/risk/exam?token=' + this.token) 
			},

			goInfo() {
				this.$router.push('/risk/info?id=' + this.term_id + '&token=' + this.token) 
			},

			changePolicy() {
				this.disabled = this.checked

				this.checked = !this.checked
			}
		}
	}
</script>

<style scoped>

.page {
	background-color: #EFEFEF;
}
.main {
	width: calc(100% - 2rem);
	height: calc(100vh - 6rem);
	overflow-y: scroll;
	background-color: #FFFFFF;
	padding: 1rem;
	border-radius: .5rem;
	position: fixed;
	top: 1rem;
	left: 1rem;
	box-sizing: border-box;
}
.footerBtn {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 1rem;
	box-sizing: border-box;
}
</style>